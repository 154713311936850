import React from 'react';

import { navigate } from 'gatsby-link';
import { useLocation } from '@reach/router';

const LANGS = ['en', 'zh', 'jp'];

const DocRedirect = () => {
  const loc = useLocation();

  React.useEffect(
    () => {
      window.setTimeout(
        () => {
          const prefered = navigator.language;
          const lang = LANGS.find(s => prefered.startsWith(s)) || 'en';
          navigate(
            `/pipy/docs/${lang}/${loc.pathname.substring(11)}`,
            { replace: true },
          );
        }, 0
      );
    }, []
  );

  return <div/>;
}

export default DocRedirect;
